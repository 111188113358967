<template>
  <div>
      <el-row class="firstrow">
          <SpeakNew @refreshList="fetchList"></SpeakNew>
          <Expandline 
              text="你关注的用户在7天内的动态"
              :buttonText="showList ? '展开' : '刷新'"
              :icon="showList ? 'Expand' : 'Refresh'"
              @click="handleClick"
          />
      </el-row>
      <OpListRow 
          :showprops="'新闻动态-用户动态'"
          ref="opListRowRef"
          :show-list="!showList"
      ></OpListRow>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import SpeakNew from '@/components/CommonComp/SpeakNew.vue'
import Expandline from '@/components/tiny_cmpt/Expandline.vue'
import OpListRow from '@/components/CommonComp/OpListRow.vue'



const showList = ref(true)
const opListRowRef = ref(null)

const fetchList = () => {
  if (opListRowRef.value) {
    opListRowRef.value.fetchList()
  }
}

const handleClick = () => {
  showList.value = !showList.value
  if (!showList.value) {
    fetchList()
  }
}
</script>

<style scoped>
.firstrow {
  margin-bottom: 0px;
}
</style>