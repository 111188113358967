<template>
    <div style="margin: 10px;font-size: 20px;">
        <UserInfoCard :itemData="detailData" />

        <el-row class="firstrow">
            以下是可以设置用户登陆密码，用户头像，用户上传的图片等。
        </el-row>
    </div>
</template>

<script>
import { ref } from 'vue';
import UserInfoCard from '../components/CommonComp/UserInfoCard.vue'

export default {
    name: 'SubPage41',
    components: {
        UserInfoCard
    },
    setup() {
        const detailData = ref({
            item_type: '人物',
            item_title: 'ceshi',
            item_subtype: '注册用户',
            item_id: 10000047,
            sparefield1: '',
            sparefield2: '',
            createtime: '',
            item_content: '',
        });

        return {
            detailData
        };
    },
}
</script>