<template>
  <div class="login">
   
    <HelloLogout></HelloLogout>
    <el-menu :default-active=activeIndex class="el-menu-demo" mode="horizontal" :ellipsis="false" @select="handleSelect"
      style="margin-left: 120px;">
      <el-menu-item index="0" class="menu-item">
        <!-- <el-tooltip content="<h2>today’s news</h2>" raw-content placement="left">今日新闻</el-tooltip> -->
        新闻动态
      </el-menu-item>
      <el-menu-item index="1" class="menu-item">
        <!-- <el-tooltip content="<h2>West Sahara Commune</h2>" raw-content>西撒公社</el-tooltip> -->
        公共事务
      </el-menu-item>
      <el-menu-item index="2" class="menu-item">
        <!-- <el-tooltip content="<h2>Friend and Group</h2>" raw-content>朋友圈子</el-tooltip> -->
        朋友圈子
      </el-menu-item>
      <el-menu-item index="3" class="menu-item">
        <!-- <el-tooltip content="<h2>followed and collected</h2>" raw-content>关注收藏</el-tooltip> -->
        关注收藏
      </el-menu-item>
      <el-menu-item index="4" class="menu-item">
        <!-- <el-tooltip content="<h2>My page</h2>" raw-content>我的主页</el-tooltip> -->
        我的主页
      </el-menu-item>
      <el-menu-item index="5" class="menu-item">
        <!-- <el-tooltip content="<h2>Public Services</h2>" raw-content>共享资源</el-tooltip> -->
        共享资源
      </el-menu-item>
      <el-menu-item index="6" class="menu-item">
        <!-- <el-tooltip content="<h2>About our plan</h2>" raw-content>本站介绍</el-tooltip> -->
        本站介绍
      </el-menu-item>
      <el-menu-item index="7" class="menu-item">
        <!-- <el-tooltip content="<h2>Encyclopedia</h2>" raw-content>安批百科</el-tooltip> -->
        安批百科
      </el-menu-item>
    </el-menu>

    <el-container>
      <el-main style="padding: 0px;">
        <el-row style="width: 1060px;" v-if="activeIndex === '0'">
          <el-col :span="3">
            <el-menu :default-active=activeSubIndex0 class="el-menu-vertical-demo bgcolor_menu_FC"
              @select="subhandleSelect0">
              <el-menu-item index="01" class="menu-item18">
                <!-- <el-tooltip content="<h2>User‘s Updates</h2>" raw-content placement="right">用户动态</el-tooltip> -->
                用户动态
              </el-menu-item>
              <el-menu-item index="02" class="menu-item18">
                <!-- <el-tooltip content="<h2>Content Updates</h2>" raw-content placement="right">内容动态</el-tooltip> -->
                内容动态
              </el-menu-item>
              <el-menu-item index="03" class="menu-item18">
                <!-- <el-tooltip content="<h2>Recommended Reading</h2>" raw-content placement="right">推荐阅读</el-tooltip> -->
                推荐阅读
              </el-menu-item>
              <el-menu-item index="04" class="menu-item18">
                <!-- <el-tooltip content="<h2>Interactive Messages</h2>" raw-content placement="right">互动信息</el-tooltip> -->
                互动信息
              </el-menu-item>
              <el-menu-item index="06" class="menu-item18">
                <!-- <el-tooltip content="<h2>System Notifications</h2>" raw-content placement="right">系统通知</el-tooltip> -->
                系统通知
              </el-menu-item>
              <el-menu-item index="05" class="menu-item18">
                <!-- <el-tooltip content="<h2>Activity History</h2>" raw-content placement="right">历史动态</el-tooltip> -->
                历史动态
              </el-menu-item>
            </el-menu>
          </el-col>

          <el-col :span="21" v-show="activeSubIndex0 === '01'">
            <SubPage01></SubPage01>
          </el-col>
          <el-col :span="21" v-show="activeSubIndex0 === '02'">
            <!-- 这里修改为SubPage02，形式见SubPage01 -->
             <SubPage02></SubPage02>
          </el-col>
          <el-col :span="21" v-show="activeSubIndex0 === '03'">
            <SubPage03></SubPage03>
             <!-- <SubPage04></SubPage04> -->

          </el-col>
          <el-col :span="21" v-if="activeSubIndex0 === '04'">
            <!-- <SubPage03></SubPage03> -->
            这里有其他用户回复和回应的信息，也有其他用户纠错和加入群组（本人为管理员）的信息
          </el-col>
          <el-col :span="21" v-if="activeSubIndex0 === '06'">
            <EmptyImage></EmptyImage>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex0 === '05'">
            <!-- <SubPage05></SubPage05> -->
          </el-col>
        </el-row>

        <el-row style="width: 1060px;" v-if="activeIndex === '1'">
          <el-col :span="3">
            <el-menu :default-active=activeSubIndex1 class="el-menu-vertical-demo bgcolor_menu_FC"
              @select="subhandleSelect1">
           
              <el-menu-item index="16" class="menu-item18">
                <span>创建组织</span>
              </el-menu-item>
              <el-menu-item index="12" class="menu-item18">
                <span>公议事项</span>
              </el-menu-item>

              <el-menu-item index="13" class="menu-item18">
                <span>组织架构</span>
              </el-menu-item>
              <el-menu-item index="14" class="menu-item18">
                <span>组织成员</span>
              </el-menu-item>
              <el-menu-item index="15" class="menu-item18">
                <span>任务列表</span>
              </el-menu-item>
              <el-menu-item index="11" class="menu-item18">
                <!-- <el-tooltip content="<h2>True story about West Sahara</h2>" raw-content placement="right">背景介绍</el-tooltip> -->
                西撒公社
              </el-menu-item>   

            </el-menu>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex1 === '11'">
            <SubPage11></SubPage11>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex1 === '12'">
            <SubPage12></SubPage12>
          </el-col>

          <el-col :span="21" v-if="activeSubIndex1 === '13'">
            <SubPage13></SubPage13>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex1 === '14'">
            这里使用SubPage_teamorusers的组件。
          </el-col>
          <el-col :span="21" v-if="activeSubIndex1 === '15'">
            <EmptyImage></EmptyImage>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex1 === '16'">
            这里使用SubPage_teamorusers的组件。
          </el-col>

        </el-row>


        <el-row style="width: 1060px;" v-if="activeIndex === '2'">
          <el-col :span="3">
            <el-menu :default-active=activeSubIndex2 class="el-menu-vertical-demo bgcolor_menu_FC"
              @select="subhandleSelect2">
              <!-- <el-menu-item index="21" class="menu-item18">
                <span>总排行榜</span>
              </el-menu-item> -->
              <el-menu-item index="22" class="menu-item18">
                <span>我关注的</span>
              </el-menu-item>
              <el-menu-item index="23" class="menu-item18">
                <span>注册用户</span>
              </el-menu-item>
              <el-menu-item index="24" class="menu-item18">
                <span>站内群组</span>
              </el-menu-item>
              <el-menu-item index="25" class="menu-item18">
                <span>现实人物</span>
              </el-menu-item>
              <el-menu-item index="26" class="menu-item18">
                <span>历史名人</span>
              </el-menu-item>
              <el-menu-item index="27" class="menu-item18">
                <span>现实团体</span>
              </el-menu-item>
            </el-menu>
          </el-col>
          <!-- 
          <el-col :span="21" v-if="activeSubIndex2 === '21'">
          </el-col> -->
          <el-col :span="21" v-if="activeSubIndex2 === '22'">
          </el-col>
          <el-col :span="21" v-if="activeSubIndex2 === '23'">
            这是协会列表
          </el-col>
          <el-col :span="21" v-if="activeSubIndex2 === '24'">
            这是所有的公社成员信息
          </el-col>
          <el-col :span="21" v-if="activeSubIndex2 === '25'">
            这是你的任务列表
          </el-col>
          <el-col :span="21" v-if="activeSubIndex2 === '26'">
            这是你的任务列表
          </el-col>
          <el-col :span="21" v-if="activeSubIndex2 === '27'">
            这是你的任务列表
          </el-col>
        </el-row>

        <el-row style="width: 1060px;" v-if="activeIndex === '3'">
          <el-col :span="3">
            <el-menu :default-active=activeSubIndex3 class="el-menu-vertical-demo bgcolor_menu_FC"
              @select="subhandleSelect3">
              <el-menu-item index="31" class="menu-item18">
                <span>标签主题</span>
              </el-menu-item>
              <el-menu-item index="32" class="menu-item18">
                <span>书名标题</span>
              </el-menu-item>
              <el-menu-item index="33" class="menu-item18">
                <span>段落内容</span>
              </el-menu-item>
              <el-menu-item index="34" class="menu-item18">
                <span>用户言论</span>
              </el-menu-item>
              <el-menu-item index="35" class="menu-item18">
                <span>人物朋友</span>
              </el-menu-item>
              <el-menu-item index="36" class="menu-item18">
                <span>团体群组</span>
              </el-menu-item>
              <el-menu-item index="37" class="menu-item18">
                <span>问答辩论</span>
              </el-menu-item>
            </el-menu>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex3 === '32'">
            这是树状公社机构列表
          </el-col>
          <el-col :span="21" v-if="activeSubIndex3 === '33'">
            这是协会列表
          </el-col>
          <el-col :span="21" v-if="activeSubIndex3 === '31'">
            这是你参加的公社和协会的公共事项
          </el-col>
          <el-col :span="21" v-if="activeSubIndex3 === '34'">
            这是所有的公社成员信息
          </el-col>
          <el-col :span="21" v-if="activeSubIndex3 === '35'">
            这是你的任务列表
          </el-col>
          <el-col :span="21" v-if="activeSubIndex3 === '36'">
            这是你的任务列表
          </el-col>
          <el-col :span="21" v-if="activeSubIndex3 === '37'">
            这是你的任务列表
          </el-col>
        </el-row>

        <el-row style="width: 1060px;" v-if="activeIndex === '4'">
          <el-col :span="3">
            <el-menu :default-active=activeSubIndex4 class="el-menu-vertical-demo bgcolor_menu_FC"
              @select="subhandleSelect4">
              <el-menu-item index="41" class="menu-item18">
                <span>我的信息</span>
              </el-menu-item>
              <el-menu-item index="42" class="menu-item18">
                <span>公众评价</span>
              </el-menu-item>
              <el-menu-item index="43" class="menu-item18">
                <span>历史动态</span>
              </el-menu-item>
              <el-menu-item index="44" class="menu-item18">
                <span>事务清单</span>
              </el-menu-item>
           
            </el-menu>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex4 === '41'">
            <!-- 这是含有我头像的页面，与detailpage中的用户信息设计类似，最后设计。这里可以设置我的头像，更改我的登录密码，匿名加密密码(一个月换一次)，
            这里发言密码，创建也可以修改，给一个十个字的提示词。每一次发布密闻，都需要输入一次密码，同时填写密码提示语言。
            在这里显示我的密码提示语和操作类型和时间。 -->
            <SubPage41></SubPage41>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex4 === '42'">
            <SubPage42></SubPage42>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex4 === '43'">
            <SubPage43></SubPage43>
            这里是我的历史动态，
          </el-col>

          <el-col :span="21" v-if="activeSubIndex4 === '44'">
           <SubPage44></SubPage44>
          </el-col>
      
        </el-row>

        <el-row style="width: 1060px;" v-if="activeIndex === '5'">
          <el-col :span="3">
            <el-menu :default-active=activeSubIndex5 class="el-menu-vertical-demo bgcolor_menu_FC"
              @select="subhandleSelect5">
              <el-menu-item index="51" class="menu-item18">
                <span>文档下载</span>
              </el-menu-item>
              <el-menu-item index="52" class="menu-item18">
                <span>数据查询</span>
              </el-menu-item>
              <el-menu-item index="53" class="menu-item18">
                <span>公共服务</span>
              </el-menu-item>
              <el-menu-item index="54" class="menu-item18">
                <span>数据分析</span>
              </el-menu-item>
              <el-menu-item index="55" class="menu-item18">
                <span>生产计划</span>
              </el-menu-item>
              <el-menu-item index="56" class="menu-item18">
                <span>公共物品</span>
              </el-menu-item>

            </el-menu>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex5 === '51'">
            <SubPage51></SubPage51>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex5 === '52'">
            <EmptyImage></EmptyImage>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex5 === '53'">
            <EmptyImage></EmptyImage>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex5 === '54'">
            <EmptyImage></EmptyImage>
          </el-col>

          <el-col :span="21" v-if="activeSubIndex5 === '55'">
            这是是生产各类生活必需品的生产计划，包含维生素蛋白质食品、内衣、工装、卫生巾等。
          </el-col>
        </el-row>


        <el-row style="width: 1060px;float: center;" v-if="activeIndex === '6'">
          <el-col :span="3">
            <el-menu :default-active=activeSubIndex6 class="el-menu-vertical-demo bgcolor_menu_FC"
              @select="subhandleSelect6">

              <el-menu-item index="61" class="menu-item18">
                <span>功能介绍</span>
              </el-menu-item>
              <el-menu-item index="62" class="menu-item18">
                <span>卡牌游戏</span>
              </el-menu-item>
              <el-menu-item index="63" class="menu-item18">
                <span>安润行动</span>
              </el-menu-item>
              <el-menu-item index="65" class="menu-item18">
                <span>极限艺术</span>
              </el-menu-item>
              <el-menu-item index="64" class="menu-item18">
                <span>管理界面</span>
              </el-menu-item>

            </el-menu>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex6 === '61'">
            <SubPage61></SubPage61>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex6 === '62'">
            <SubPage62></SubPage62>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex6 === '63'">
            <SubPage63></SubPage63>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex6 === '65'">
            <SubPage65></SubPage65>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex6 === '64'">
            <SubPage64></SubPage64>
            以下为测试模块：
            <br>
            <router-link :to="{ name: 'DetailPage', params: { id: 10002491 } }" target="_blank">  
              查看10002491段落
            </router-link>
--
<router-link :to="{ name: 'DetailPage', params: { id: 10000003 } }" target="_blank">  
              查看10000003文章
            </router-link>
--
<router-link :to="{ name: 'DetailPage', params: { id: 10000004 } }" target="_blank">  
              查看10000004物品
            </router-link>
--
<router-link :to="{ name: 'DetailPage', params: { id: 10000005 } }" target="_blank">  
              查看10000005标签
            </router-link>
--
<router-link :to="{ name: 'DetailPage', params: { id: 10000006 } }" target="_blank">  
              查看10000006人物
            </router-link>
--
<router-link :to="{ name: 'DetailPage', params: { id: 10000007 } }" target="_blank">  
              查看10000007群组
            </router-link>
--
<router-link :to="{ name: 'DetailPage', params: { id: 10000008 } }" target="_blank">  
              查看10000008契约
            </router-link>
--
<router-link :to="{ name: 'DetailPage', params: { id: 10000009 } }" target="_blank">  
              查看10000009辩论
            </router-link>
--
<router-link :to="{ name: 'DetailPage', params: { id: 10000010 } }" target="_blank">  
              查看10000010选举
            </router-link>
--
            <router-link :to="{ name: 'PS_reply_page', params: { id: 100000001 } }" target="_blank">  
              查看100000001附言
            </router-link>
--
<br>


          </el-col>
        </el-row>

        <el-row style="width: 1060px;" v-if="activeIndex === '7'">
          <el-col :span="3">
            <el-menu :default-active=activeSubIndex7 class="el-menu-vertical-demo bgcolor_menu_FC"
              @select="subhandleSelect7">
              <el-menu-item index="71" class="menu-item18">
                <span>词条排行</span>
              </el-menu-item>
              <el-menu-item index="72" class="menu-item18">
                <span>恶人榜单</span>
              </el-menu-item>
              <el-menu-item index="73" class="menu-item18">
                <span>旧闻跟踪</span>
              </el-menu-item>
              <el-menu-item index="74" class="menu-item18">
                <span>知识地图</span>
              </el-menu-item>
              <el-menu-item index="75" class="menu-item18">
                <span>解密爆料</span>
              </el-menu-item>
              <el-menu-item index="76" class="menu-item18">
                <span>辟谣专区</span>
              </el-menu-item>
            </el-menu>
          </el-col>
          <el-col :span="21" v-if="activeSubIndex7 === '71'">
            <!-- <SubPage71></SubPage71> -->
          </el-col>
          <el-col :span="21" v-if="activeSubIndex7 === '72'">
            <!-- <SubPage72></SubPage72> -->
          </el-col>
          <el-col :span="21" v-if="activeSubIndex7 === '73'">
            这是协会列表
          </el-col>

          <el-col :span="21" v-if="activeSubIndex7 === '74'">
            这是74
          </el-col>

          <el-col :span="21" v-if="activeSubIndex7 === '75'">
            这是75
          </el-col>

          <el-col :span="21" v-if="activeSubIndex7 === '76'">
            这是
          </el-col>
        </el-row>




      </el-main>


      <el-aside class="aside-left">
        <el-tooltip content="<h2>create new items</h2>" raw-content placement="left">
          <el-button class="flat-button">
            <router-link :to="{ name: 'create_apage' }" target="_blank" class="grey-link" style="text-decoration: none;">增</router-link>
          </el-button>
        </el-tooltip>
        <el-button class="flat-button">
          <router-link :to="{ name: 'search_apage' }" target="_blank" class="grey-link" style="text-decoration: none;">搜</router-link>
        </el-button>
        <el-button class="flat-button">
          <router-link :to="{ name: 'chat_apage' }" target="_blank" class="grey-link" style="text-decoration: none;">聊</router-link>
        </el-button>
        <el-button class="flat-button">
          <router-link :to="{ name: 'ai_apage' }" target="_blank" class="grey-link" style="text-decoration: none;">AI</router-link>
        </el-button>
        <el-button class="flat-button">
          <router-link :to="{ name: 'ai_apage' }" target="_blank" class="grey-link" style="text-decoration: none;">
          <span style="font-size: 20px;">En</span>
          </router-link>
        </el-button>
      </el-aside>
    </el-container>
    <el-dialog       
      v-model="dialogVisible"
      title=""
      :modal="true"
      :close-on-click-modal="false"
      :lock-scroll="true" 
      style="width:500px;height:400px;">  

        <el-row class="custom-background">
          <el-col :span="8">

          </el-col>
          <el-col :span="16">
            <br>
            <h2>登录Login</h2>
            <el-form ref="loginform" v-model="loginForm">
              <br>
              <input 
                autocomplete="off" 
                type="text" 
                placeholder="你的用户名,your username" 
                v-model="loginForm.username"
                class="input_dengluye">
              <br><br><br>
              <input 
                type="password" 
                placeholder="你的密码,your password" 
                v-model="loginForm.password"
                class="input_dengluye">
              <br>
            </el-form>
            <br>
            <h2><a class="a_grey" @click="handleLogin">确定Confirm</a></h2>
            <br>
            <br>
            <br>
          
            <h3>注册,to register</h3>
            <br>
            <h3>找回密码,Reset Password</h3>
            <br>


          </el-col>
        </el-row>
    </el-dialog>  


  </div>
</template>

<script>


import { ref, onMounted, reactive  } from 'vue';
// import { ElMenu, ElMenuItem } from 'element-plus';  
// import 'element-plus/lib/theme-chalk/index.css';  
import HelloLogout from '../components/CommonComp/HelloLogout.vue';
import EmptyImage from '../components/tiny_cmpt/EmptyImage.vue';

import SubPage65 from '../main_subpage/SubPage65.vue';
import SubPage64 from '../main_subpage/SubPage64.vue';
import SubPage63 from '../main_subpage/SubPage63.vue';
import SubPage62 from '../main_subpage/SubPage62.vue';
import SubPage61 from '../main_subpage/SubPage61.vue';
import SubPage01 from '../main_subpage/SubPage01.vue';
import SubPage02 from '../main_subpage/SubPage02.vue';
import SubPage03 from '../main_subpage/SubPage03.vue';
import SubPage05 from '../main_subpage/SubPage05.vue';
import SubPage11 from '../main_subpage/SubPage11.vue';
import SubPage12 from '../main_subpage/SubPage12.vue';
import SubPage13 from '../main_subpage/SubPage13.vue';
import SubPage44 from '../main_subpage/SubPage44.vue';
import SubPage42 from '../main_subpage/SubPage42.vue';
import SubPage43 from '../main_subpage/SubPage43.vue';
import SubPage41 from '../main_subpage/SubPage41.vue';
import SubPage51 from '../main_subpage/SubPage51.vue';
import SubPage71 from '../main_subpage/SubPage71.vue';
import SubPage72 from '../main_subpage/SubPage72.vue';
import CreatenewRow from '@/components/CommonComp/CreatenewRow.vue';
import axios from 'axios'
import { ElMessage } from 'element-plus'
import OpListRow from '@/components/CommonComp/OpListRow.vue';



export default {

  components: {
    HelloLogout,
    EmptyImage,
    SubPage01,
    SubPage02,
    SubPage03,
    SubPage05,
    SubPage11,
    SubPage12,
    SubPage13,
    SubPage65,
    SubPage64,
    SubPage62,
    SubPage63,
    SubPage61,
    SubPage44,
    SubPage42,
    SubPage41,
    SubPage43,
    SubPage51,
    SubPage71,
    SubPage72,
    OpListRow,


  },



  setup() {

    const error0 = ref('');  
    const message0 = ref('');  


    const activeIndex = ref('6'); // 默认选中新闻动态  
    const activeSubIndex0 = ref('01');
    const activeSubIndex1 = ref('16');
    const activeSubIndex2 = ref('21');
    const activeSubIndex3 = ref('31');
    const activeSubIndex4 = ref('41');
    const activeSubIndex5 = ref('51');
    const activeSubIndex6 = ref('64');
    const activeSubIndex7 = ref('71');


    const handleSelect = (index) => {
      activeIndex.value = index;
    };

    const subhandleSelect0 = (index) => {
      activeSubIndex0.value = index;
    };

    const subhandleSelect1 = (index) => {
      activeSubIndex1.value = index;
    };

    const subhandleSelect2 = (index) => {
      activeSubIndex2.value = index;
    };

    const subhandleSelect3 = (index) => {
      activeSubIndex3.value = index;
    };

    const subhandleSelect4 = (index) => {
      activeSubIndex4.value = index;
    };

    const subhandleSelect5 = (index) => {
      activeSubIndex5.value = index;
    };

    const subhandleSelect6 = (index) => {
      activeSubIndex6.value = index;
    };

    const subhandleSelect7 = (index) => {
      activeSubIndex7.value = index;
    };

    
    const dialogVisible = ref(false);

    const loginForm = reactive({
      username: '',
      password: ''
    });

    const handleLogin = async () => {
      if (!loginForm.username || !loginForm.password ) {  
          // 如果任一密码为空或不相等，设置错误消息并返回  
          error0.value = '输入不为空';  
          message0.value = '';  
          return; // 停止执行后续代码  
        }  

        try {
          const response = await axios.post('https://www.aworld.wiki/api/iteminfo/login/', {
            username: loginForm.username,
            password0: loginForm.password,
          });
          message0.value = response.message;
          error0.value = '';
          localStorage.setItem('username', loginForm.username); 
          localStorage.setItem('userid', response.data.userid);
          // ceshi.value = localStorage.getItem('username');
          dialogVisible.value = false;
          window.location.href = '/';

        } catch (e) {
          error0.value = e.error;
          message0.value = '';
        }
};


    onMounted(async () => {  
      const username = localStorage.getItem('username');
      if (!username) {
        dialogVisible.value = true;
      }
    });


    return {
      activeIndex,
      activeSubIndex0,
      activeSubIndex1,
      activeSubIndex2,
      activeSubIndex3,
      activeSubIndex4,
      activeSubIndex5,
      activeSubIndex6,
      activeSubIndex7,
      handleSelect,
      subhandleSelect0,
      subhandleSelect1,
      subhandleSelect2,
      subhandleSelect3,
      subhandleSelect4,
      subhandleSelect5,
      subhandleSelect6,
      subhandleSelect7,
      dialogVisible,
      handleLogin,
      loginForm,
      error0,
      message0
    

    };
  },
};  
</script>

<style>
.login {
  width: 1280px;
  margin: 0 auto;
}

.header {
  width: 1280px;
  background-color: #708090;
  ;
  height: 40px;
  color: white;
  font-size: 40px;
  line-height: 40px;
  text-align: left;
  padding: 0;
}



.menu-item {
  font-size: 20px;
  color: gray !important;
}

.menu-item18 {
  font-size: 18px;
  /* color: gray !important; */
  text-align: center;
}

.el-menu-vertical-demo {
  border-right: none !important;
}

.aside-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 120px;
  height: 300px;

}

.flat-button {
  margin-bottom: 0px !important;
  padding: 15px;
  height: 56px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  color: #666;
  font-size: 25px;
  cursor: pointer;
}

.flat-button:hover {
  background-color: #f2f2f2;
}




.custom-background {  
  background-image: url('https://aworld-1302616346.cos.ap-hongkong.myqcloud.com/static_pic/login2.jpg');  /* 替换为你的图片URL */  
  background-size: cover;  
  height: 100%; /* 确保高度覆盖整个dialog */  
  width: 100%; /* 确保宽度也覆盖 */  
  position: absolute; /* 可能需要绝对定位来覆盖默认背景 */  
  top: 0;  
  left: 0;  
  /* 可能还需要其他样式来确保内容不被遮挡 */  
}  

.input_dengluye{
  background-color:rgba(0,0,0,0);
  border-top:0px;
  border-left:0px;
  border-right:0px;
  border-color:grey;
  height: 30px;
  width:80%;
  color:black;
  font-size: 20px;
  outline:none;}
</style>
